import globalStore from '../store/globalStore'
import { EnumI } from '@/types/skuEditor'
import store from '../store'
import { format } from 'date-fns'

// In the case of a field which has items, this method will attempt to retrieve the items
// The items are there because they make sense in the UI therefore they must meet the UI's needs
function getItems(field: string): string[] | EnumI[] {
  return globalStore.getItems(field, store)
}

const campaignIdsToNames = function(keys: string): string {
  if (!keys) return ''
  const campaigns = globalStore.getLatestCollectionObject('campaign')
  return keys
    .split(',')
    .map((key) => campaigns[key]?.campaignName)
    .join(',')
}

const countImages = function(f: string): number {
  if (f.length === 0) return 0
  const images = f.split(',').length || 0
  return images
}
const formatDate = function(d: number): string {
  if (!d) return ''
  return format(d, 'yy-MM-dd HH:mm')
}

export default { getItems, campaignIdsToNames, countImages, formatDate }
