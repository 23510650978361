<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn v-show="show" class="mx-0 px-0" large icon :color="color" v-bind="$attrs" v-on="on" @click="$emit('click', $event)">
        <v-icon :color="iconcolor">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'SwButton',
  inheritAttrs: false,
  props: {
    icon: { type: String, required: false, default: '' },
    color: { type: String, required: false, default: '' },
    iconcolor: { type: String, required: false, default: '' },
    tooltip: { type: String, required: false, default: '' },
    show: { type: Boolean, require: false, default: true },
  },
}
</script>
