<template>
  <v-dialog v-model="value" persistent max-width="800" width="600px">
    <v-card class="pb-2 white">
      <v-card class="sticky-element px-2 mb-2 mx-0">
        <v-card-text>
          <v-row data-test="brand-title mx-1 px-0">
            <v-col cols="12" sm="3" class="d-flex justify-start">
              <div class="text-h5">{{ swT('import_sku_history') }}</div>
            </v-col>
            <v-col cols="12" sm="9" class="d-flex align-center justify-end">
              <v-card-actions>
                <v-btn class="mx-1" color="error" @click="$emit('operation', { done: true })">{{ swT('close') }}</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card-text>
        <v-text-field v-if="files.length > 0" v-model="search" append-icon="mdi-magnify" label="Search" dense outlined hide-details class="my-3"></v-text-field>
        <v-data-table v-if="files.length > 0" :headers="headers" :items="files" :search="search" :items-per-page="5" @click:row="downloadFile"></v-data-table>
        <v-alert v-else type="info">{{ swT('no_files_available') }}</v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'
import webServices from '../functions/webServicesFacade'
import { hashBrand } from '@softwear/latestcollectioncore'

export default {
  props: ['value', 'files', 'brand', 'operation'],
  data() {
    return {
      swT,
      search: '',
      headers: [{ text: swT('name'), value: 'fileName' }],
    }
  },
  deactivated() {
    this.$destroy()
  },
  methods: {
    async downloadFile(selectedFile) {
      try {
        const response = await webServices.getSkuImportHistoryFile(hashBrand(this.brand), selectedFile.fileName)
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
        const tag = document.createElement('a')
        tag.href = fileUrl
        tag.download = selectedFile.fileName
        document.body.appendChild(tag)
        tag.click()
        document.body.removeChild(tag)
      } catch (error) {
        this.$store.dispatch('raiseAlert', {
          header: swT('downloadFailed'),
          type: 'error',
          timeout: 3000,
        })
      }
    },
  },
}
</script>
