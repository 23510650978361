<template>
  <span>
    {{ typeof amount == 'number' ? '€ ' + amount.toFixed(2) : '' }}
  </span>
</template>

<script>
export default {
  props: ['amount'],
}
</script>
