import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.showChooseNewSkuMethod,"persistent":"","max-width":"500"},on:{"input":function($event){return _vm.$emit('input')}}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline info white--text py-4"},[_c(VSpacer),_c(VBtn,{attrs:{"data-test":"cancelButton","color":"info","large":""},nativeOn:{"click":function($event){return _vm.closeDialog.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.swT('cancel')))])],1),_c(VCardText,[_c(VCard,{staticClass:"my-6",staticStyle:{"cursor":"pointer","min-height":"80px"},attrs:{"color":"green lighten-3 d-flex align-center justify-center","data-test":"scanBarcodes"},nativeOn:{"click":function($event){return _vm.addBarcodes()}}},[_c(VCardText,{staticClass:"pl-6"},[_c('div',{staticClass:"text-h5 text-center"},[_vm._v(" "+_vm._s(_vm.swT('product_has_barcode'))+" ")])])],1),_c(VCard,{staticStyle:{"cursor":"pointer","min-height":"80px"},attrs:{"color":"warning lighten-3 d-flex align-center","data-test":"isGeneratingBarcodes"},nativeOn:{"click":function($event){return _vm.generateBarcodes()}}},[_c(VCardText,{staticClass:"px-6"},[_c('div',{staticClass:"text-h5 text-center"},[_vm._v(" "+_vm._s(_vm.swT('product_has_no_barcode'))+" ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }